<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>User Profile</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/user/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            items: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: ''},
                { key: 'value', label: ''}
            ]
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('system/users/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    res.data.map(val => {
                        switch(val.is_active){
                            case true:
                                val.is_active = 'Active';
                                break;
                            case false:
                                val.is_active = 'Inactive';
                                break;
                        }
                        switch(val.is_guest){
                            case true:
                                val.is_guest = 'Yes';
                                break;
                            case false:
                                val.is_guest = 'No';
                                break;
                        }
                        switch(val.sex){
                            case 1:
                                val.sex = 'Male';
                                break;
                            case 2:
                                val.sex = 'Female';
                                break;
                            case 3:
                                val.sex = 'Other';
                                break;
                        }
                        if(val.last_login != '0001-01-01T00:00:00Z'){
                            val.last_login = this.$moment(val.last_login).format('YYYY-MM-DD HH:mm:ss');
                        }
                        val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        this.items.push(
                            { key: 'ID', value: val.id },
                            { key: 'E-mail', value: val.username },
                            { key: 'First Name', value: val.first_name },
                            { key: 'Last Name', value: val.last_name },
                            { key: 'Sex', value: val.sex },
                            { key: 'State', value: val.is_active },
                            { key: 'Guest User', value: val.is_guest },
                            { key: 'Created at', value: val.created_at },
                            { key: 'Last Login', value: val.last_login },
                        );
                    })
                })
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
